import { FC, HTMLAttributes } from "react"

import { useIsMounted } from "src/hooks/isMounted"

import { BaseLink, BaseLinkProps } from "./BaseLink"

const hasHref = (
  props: BaseLinkProps | HTMLAttributes<HTMLDivElement>,
): props is BaseLinkProps => "href" in props && !!props.href

type DivProps = HTMLAttributes<HTMLDivElement>
type AnchorProps = BaseLinkProps & {
  href?: string
}

export type BaseLinkOrDivProps = DivProps | AnchorProps

export const BaseLinkOrDiv: FC<BaseLinkOrDivProps> = (props) => {
  const isMounted = useIsMounted()

  // We need to wait for the browser
  // Otherwise this component returns a hydration error.
  if (!isMounted) return null

  // render link
  if (hasHref(props)) return <BaseLink {...props} />

  // render div
  return <div {...props} />
}
