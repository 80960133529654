import React, { FC } from "react"

import { CopyText, CopyTextProps } from "src/components/cck/elements/copyText"
import { Headline, HeadlineProps } from "src/components/cck/elements/headline"
import {
  ContentfulImage,
  ContentfulImageProps,
} from "src/components/cck/elements/image"
import { BaseLinkOrDiv } from "src/components/common/base-link/BaseLinkOrDiv"
import { Icon, IconName } from "src/components/common/icon"
import { create } from "src/helpers/bem"
import { ImageSourceSizes } from "src/helpers/contentful/createContentfulImageSources"

import { generateTrackingEvent } from "src/helpers/tracking/generateTrackingEvent"

import styles from "./EditorialTeaser.module.scss"

const bem = create(styles, "EditorialTeaser")

export const getImageSizes = (size: EditorialTeaserSize): ImageSourceSizes => {
  const ratios = {
    xs: 3,
    sm: 3,
    md: 2,
    lg: 2,
  }
  const ratio = ratios[size]

  return [
    { maxScreenSize: 320, width: 296, height: 206, scales: [1, 1.5] },
    { maxScreenSize: 480, width: 456, height: 206, scales: [1, 1.5] },
    { maxScreenSize: 640, width: 616, height: 206, scales: [1, 1.5] },
    { maxScreenSize: 768, width: 744, height: 236, scales: [1, 1.5] },
    { maxScreenSize: 828, width: 828 / ratio, height: 236, scales: [1, 1.5] },
    { maxScreenSize: 1080, width: 1080 / ratio, height: 236, scales: [1, 1.5] },
    { maxScreenSize: 1278, width: 1278 / ratio, height: 236, scales: [1, 2] },
    { maxScreenSize: 1440, width: 1440 / ratio, height: 236, scales: [1, 1.5] },
    { maxScreenSize: 1920, width: 1920 / ratio, height: 236, scales: [1, 1.5] },
  ]
}

type EditorialTeaserSize = "xs" | "sm" | "md" | "lg"

export type EditorialTeaserProps = {
  /** A Headline item */
  headline: HeadlineProps
  /** Shows a border to the left of the title if true */
  headlineHighlight?: boolean
  /** A Headline item — must be set with a lower level than the `headline` property */
  subHeadline?: HeadlineProps
  /** A Copy Text item */
  copyText?: CopyTextProps
  /** A Image item */
  image?: ContentfulImageProps
  /** Changes the background when there's no image and there's an icon */
  theme?: "yellow" | "blue" | "white"
  /**
   * The size of the element.
   *
   * In desktop view: `lg` occupies the whole space, `md` is 50%, and `sm` fits three on a line */
  size?: EditorialTeaserSize
  /** Only appears if there's no image */
  icon?: IconName
  /** Link to target when clicked */
  link?: string
  /** Tracking information */
  tracking?: Record<string, any> | null
}

/**
 * Simple teaser module, which primarily serve to introduce further topics in a comparatively discreet way.
 */
export const EditorialTeaser: FC<EditorialTeaserProps> = ({
  headline,
  subHeadline,
  copyText,
  image,
  theme = "blue",
  size = "lg",
  icon,
  link,
  headlineHighlight = true,
  tracking,
}) => {
  const hasImage = !!image?.url

  const sizeModifiers = {
    [size]: true,
  }

  const themeModifiers = {
    [theme]: !hasImage,
  }

  const onClick = () => {
    if (tracking) generateTrackingEvent(tracking)
  }

  return (
    <BaseLinkOrDiv
      className={bem(undefined, sizeModifiers)}
      href={link}
      onClick={onClick}
    >
      <div
        className={bem("image", {
          ...themeModifiers,
          ...sizeModifiers,
        })}
      >
        {hasImage ? (
          <ContentfulImage {...image} imageSizes={getImageSizes(size)} />
        ) : (
          !!icon && <Icon name={icon} className={bem("icon")} />
        )}
      </div>

      <div className={bem("content", sizeModifiers)}>
        <div
          className={bem("headline", {
            "has-highlight": headlineHighlight,
          })}
        >
          {subHeadline && <Headline {...subHeadline} />}
          <Headline {...headline} />
        </div>

        <div>{copyText && <CopyText {...copyText} />}</div>
      </div>
    </BaseLinkOrDiv>
  )
}
